<template>
  <ion-list v-for="i in 10" :key="i">
    <ion-item>
      <ion-thumbnail slot="start">
        <ion-skeleton-text class="rounded-full" :animated="true"></ion-skeleton-text>
      </ion-thumbnail>
      <ion-label>
        <h3>
          <ion-skeleton-text :animated="true" class="rounded-sm" style="width: 40%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text :animated="true" class="rounded-sm" style="width: 20%"></ion-skeleton-text>
        </p>
        <p>
          <ion-skeleton-text :animated="true" class="rounded-sm" style="width: 80%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-list>
</template>
<script>
export default {
  name: 'SkeletonSelectCustomer'
};
</script>
<style lang="scss" scoped>
.rounded-full {
  border-radius: 50%;
}
.rounded-sm {
  border-radius: 2px;
}
</style>
