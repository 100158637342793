import { ACTIONS } from '@/modules/b2b/store/address/actions';
import { ACTIONS as ACTIONS_CUSTOMER } from '@/modules/b2b/store/customer/actions';

import { banOutline, chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import { computed, onMounted, ref } from 'vue';
import { useConfig, useGetStorageData } from '..';

const useGetAddresses = (props, addresses = []) => {
  const { t, store, storePath } = useConfig();
  const { selectedCompany } = useGetStorageData();
  const displayAddress = () => {
    const activeAddresses = addresses?.value?.filter((address) => address.active) || {};
    const defaultAddresses = addresses?.value?.filter((address) => address.is_default) || {};
    if (defaultAddresses.length > 0) {
      const { asString1 } = defaultAddresses.sort((a, b) => b.id - a.id)[0];
      return asString1;
    } else if (activeAddresses.length > 0) {
      const { asString1 } = activeAddresses.sort((a, b) => b.id - a.id)[0];
      return asString1;
    } else {
      return t('not_provided_address');
    }
  };

  const getAddresses = async () => {
    const { id } = await selectedCompany.value;
    await store.dispatch(`${storePath}/address/${ACTIONS.GET_ADDRESSES}`, {
      supplierId: props?.selectedCompany?.id ?? id,
      active: true
    });
  };

  return {
    getAddresses,
    displayAddress
  };
};

export const useCheckUserCompany = () => {
  // if user has > 1 company || user has 1 company but has > 1 company branches, then true
  const { store, storePath, isMerchantUser } = useConfig();
  const { selectedCompany, selectedUser } = useGetStorageData();
  const STORE_MODULE_PATH = `${storePath}/customer`;
  const companyList = computed(() => store.getters[`${STORE_MODULE_PATH}/list`]);
  const isEnabledChangeCompanyRef = ref(false);
  const isEnabledChangeCompany = async () => {
    const { branches: branchListSelectedCompany } = await selectedCompany.value;
    const { tenant } = await selectedUser.value;
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS_CUSTOMER.UPDATE_QUERY_PARAMS}`, {
      searchQuery: ``,
      offset: 0,
      isLoadMore: false,
      tenant_id: tenant.id
    });
    await store.dispatch(`${STORE_MODULE_PATH}/${ACTIONS_CUSTOMER.GET_CUSTOMERS}`, {
      isLoadMore: false
    });
    const dataSourceLength = isMerchantUser
      ? companyList.value.data.length > 1
      : companyList.value.items.length > 1;

    if (dataSourceLength || branchListSelectedCompany.length > 1) {
      return true;
    } else {
      return false;
    }
  };
  return {
    isEnabledChangeCompanyRef,
    isEnabledChangeCompany
  };
};

export const useCardChangeCompany = (props) => {
  const { store, storePath, isMerchantUser } = useConfig();
  const isOpenCompanySelection = ref(false);
  const setOpenCompanySelection = (state) => (isOpenCompanySelection.value = state);

  const addresses = computed(() => store.getters[`${storePath}/address/list`]);
  const { getAddresses, displayAddress } = useGetAddresses(props, addresses);
  const { isEnabledChangeCompany, isEnabledChangeCompanyRef } = useCheckUserCompany();
  onMounted(async () => {
    await getAddresses();
    await isEnabledChangeCompany();
  });

  return {
    displayAddress,
    addresses,
    isEnabledChangeCompanyRef,
    isEnabledChangeCompany,
    isMerchantUser,
    isOpenCompanySelection,
    setOpenCompanySelection,
    chevronDownOutline,
    banOutline,
    chevronUpOutline
  };
};
