import whatsappIcon from '@/assets/icons/shared/wa.svg';
import { getAddressFormatWithStall } from '@/modules/b2b/services/libs/helper';
import { handleRandomBackground } from '@/modules/shared/utils';
import { banOutline } from 'ionicons/icons';
import { computed, onMounted, ref } from 'vue';
import { useConfig } from '..';
const { VUE_APP_ADMIN_CONTACT } = process.env;

export const useCardCompany = (props, context) => {
  const { router, isMerchantUser, storePath } = useConfig();
  const selectCustomerPath = router.currentRoute.value.path.endsWith('/select-customer');
  const isDefaultImage = ref(false);
  const randomBg = ref(null);
  const onRedirectToDetail = (id) => {
    if (props.activeTab === 'myCustomer') {
      router.push(`/${storePath}/customers/${id}?tagged=true`);
    } else {
      router.push(`/${storePath}/customers/${id}`);
    }
  };
  const onTapCustomerCard = (id) => {
    if (selectCustomerPath) {
      context.emit('onSelectCustomer');
    } else {
      if (isMerchantUser) {
        if (props.id === 'showDetail') {
          onRedirectToDetail(id);
        }
      } else {
        onRedirectToDetail(id);
      }
    }
  };
  const addressCompanyinReq = computed(() => getAddressFormatWithStall(props.item?.customer?.addresses[0]));
  const onTapContactUs = () => {
    window.open(`https://wa.me/${VUE_APP_ADMIN_CONTACT}`, '_blank');
  };

  const getInitialName = (name) => {
    return name.trim().split(' ')[0].toUpperCase()[0];
  };
  onMounted(() => {
    if (!props.imageUrl) {
      isDefaultImage.value = true;
      randomBg.value = handleRandomBackground();
    }
  });
  return {
    isDefaultImage,
    randomBg,
    whatsappIcon,
    addressCompanyinReq,
    getAddressFormatWithStall,
    handleRandomBackground,
    onTapContactUs,
    getInitialName,
    onTapCustomerCard,
    banOutline
  };
};
